<template>
  <main>
    <trac-modal @close="close">
      <img src="@/assets/svg/award.svg" class="-mt-12 ml-10" alt="" />
      <div class="ml-10 mt-10 mr-12">
        <h1 class="text-accentDark font-medium text-sm">
          Please complete your Traction profile...
        </h1>
        <p class="smallest pr-20 mt-2">
          To unlock all your Traction wallet features upload all your documents
          and activate your account.
        </p>
        <div
          class="relative flex items-center py-3 leading-7 rounded w-full border border-accentBlue mt-6"
        >
          <svg
            class="absolute -ml-3 w-6 h-6"
            viewBox="0 0 28 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              cx="14"
              cy="14"
              r="12.4155"
              fill="#B2E7FF"
              stroke="#EFFAFF"
              stroke-width="3.16909"
            />
            <path
              d="M20.5391 10.1818L11.6687 19.0522L7.63672 15.0202"
              stroke="#EFFAFF"
              stroke-width="3.18182"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <div class="flex px-4 items-center w-full justify-between">
            <p class="uppercase font-medium text-xs pl-2 text-gradientLight">
              Add Your Personal Documents 3+
            </p>
            <div>
              <svg
                class="w-6 h-6"
                viewBox="0 0 30 30"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M0 6C0 2.68629 2.68629 0 6 0H24C27.3137 0 30 2.68629 30 6V24C30 27.3137 27.3137 30 24 30H6C2.68629 30 0 27.3137 0 24V6Z"
                  fill="#EFFAFF"
                />
                <path
                  d="M20.8584 16.9053V19.5405C20.8584 20.2678 20.2681 20.8581 19.5408 20.8581H10.3176C9.59028 20.8581 9 20.2678 9 19.5405V16.9053"
                  stroke="#003283"
                  stroke-width="1.464"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M18.2228 12.294L14.9288 9L11.6348 12.294"
                  stroke="#003283"
                  stroke-width="1.464"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M14.929 9V16.9056"
                  stroke="#003283"
                  stroke-width="1.464"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <input type="file" name="" id="" class="hidden" />
            </div>
          </div>
        </div>
        <div class="flex justify-end pt-12 smaller pb-10">
          <trac-button class="smallest"> COMPLETE NOW</trac-button>
        </div>
      </div>
    </trac-modal>
  </main>
</template>

<script>
export default {
  methods: {
    close() {
      this.$emit('close')
    }
  },
}
</script>

<style>
</style>>