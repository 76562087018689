<template>
  <main class="mt-5 pl-5">
    <trac-topnav />
    <h2>List of all custom components.</h2>
    <div class="mt-5">
      <h3>All Buttons</h3>
      <trac-button class="ml-4" @click.native="check">
        Transfer to new beneficiary
      </trac-button>
      <trac-button
        :loading="true"
        @click.native="check"
        variant="outline"
        class="ml-4"
      >
        Transfer to new beneficiary
      </trac-button>
      <p @click="loading = !loading" class="cursor-pointer">Loading</p>

      <trac-button @click.native="check" class="ml-4" disabled>
        Transfer to new beneficiary
      </trac-button>
      <trac-button
        @click.native="check"
        variant="outline"
        class="ml-4 mt-4"
        disabled
      >
        Transfer to new beneficiary
      </trac-button>
      <trac-button class="ml-4" @click.native="check" variant="small" disabled>
        Buy Airtime
      </trac-button>
      <trac-button class="ml-4" @click.native="check" variant="icon">
        Buy Airtime
      </trac-button>
      <trac-button
        class="ml-4 uppercase font-bold"
        @click.native="check"
        variant="icon"
      >
        ALL beneficiaries
      </trac-button>
    </div>
    <div class="ml-64">
      <h3 class="mr-6 mb-2 mt-5">Dropdown</h3>
      <trac-dropdown v-model="drop" :menuItems="menuItems"></trac-dropdown>
    </div>
    <div class="mt-6 ml-4 flex">
      <h3 class="mr-6">Toggle</h3>
      <!-- <trac-toggle
        @update="toggleOne = $event"
        :active="toggleOne"
      ></trac-toggle>
      {{ toggleOne }} -->
      <trac-toggle
        @newval="updateData"
        class="ml-6"
        :active="toggle"
      ></trac-toggle>
      {{ toggle }}gg
      <trac-toggle
        @newval="updateData"
        class="ml-3"
        :active="toggleTwo"
        :disabled="true"
      ></trac-toggle>
    </div>
    <div class="mt-6 ml-4">
      <h3 class="mr-6 mb-2">Checkbox</h3>
      <trac-checkbox></trac-checkbox>
      <trac-checkbox :disableCheckbox="true" :isChecked="false"></trac-checkbox>
    </div>

    <div class="mt-6 ml-4">
      <h3 class="mr-6 mb-2">Radio</h3>
      <trac-radio radioLabel="label 1"></trac-radio>
      <trac-radio
        radioLabel="Disabled"
        :disableCheckbox="true"
        :isChecked="false"
      ></trac-radio>
    </div>
    <div class="mt-6">
      <h3 class="mr-6 mb-2">Input</h3>
      <trac-input
        placeholder="Full Name"
        v-model="model.a"
        class="w-64 mt-4"
        disabled
      />
      <trac-input placeholder="Full Name" v-model="model.b" class="w-64 mt-4" />
    </div>
    <h3 @click="modal = true" class="mr-6 mb-2 mt-4">modal</h3>

    <CompletionModal
      v-if="modal"
      class="max-w-2xl w-full"
      @close="modal = false"
    />
    <trac-loading v-if="loading" />
  </main>
</template>

<script>
import CompletionModal from '@/components/custom/CompletionModal'
export default {
  data() {
    return {
      loading: false,
      toggleOne: false,
      toggle: false,
      toggleTwo: true,
      modal: false,
      model: {
        a: 'look',
        b: 'ishie'
      },
      menuItems: [
        { 'name': 'filter1', 'value': 1 },
        { 'name': 'filter2', 'value': 2 },
        { 'name': 'filter3', 'value': 3 },
        { 'name': 'filter4', 'value': 4 },
      ],
      drop: { 'name': 'filter2', 'value': 2 }
    }
  },
  components: {
    CompletionModal
  },
  methods: {
    updateData(x) {
      this.toggle = !x
    }
  },
}
</script>